body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  min-height: 100vh;

  background-color: #090A18;
  color: white;
}

.App-root-view{
  flex: 1;
  display: flex;
  flex-direction: column;
}

.footer_container{
  padding-top: 4rem;
  padding-bottom: 2rem;
  background-color: #8b55ff00;
  border-top: 1px solid #8b55ff8c;
}

.bold_h6{
  font-weight: 700;
}

.text-sm{
  font-size: .9rem;
}

.text-grayed{
  color: #ffffffb2;
}

.link_footer{
  cursor: pointer;
  color: #ffffff8e;
}

.link_footer:hover{
  text-decoration: underline;
  color: #ffffff;
}

.gap-column-sm{
  display: flex;
  flex-direction: column;
  gap: .3rem;
}

.navbar-nav > .active{
  color: white !important;
  border-bottom: 2px solid #8b55ff;

  transform: .3s;
}

.navbar-nav{
  justify-content: flex-end !important;
  width: 100%;
  flex-direction: row !important;
  align-items: center !important;
}

.text_error{
  color: red;
  text-align: center;
  margin-top: .5rem;
  padding: 0 !important;
  font-weight: 100;
  font-size: .9rem;
  border: 1px solid red;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.034);
}

.text_success{
  color: green;
  text-align: center;
  padding-top: .5rem;
  font-weight: 100;
  font-size: .9rem;
}

.navbar-expand-lg{
  background-color: #090a17 !important;
  color: white !important;
}

.navbar-nav > a, .navbar-nav > .active {
  color: white !important;
}

.navbar-nav{
  justify-content: space-around !important;
}

.modal-content{
  background-color: #1F1D2F !important;
}

.dashboard_page{
  padding-top: 3rem;
}