.terminal {
    background-color: black;
    color: green;
    font-family: 'Courier New', Courier, monospace;
    padding: 10px;
    border: 2px solid green;
    height: 400px;
    overflow-y: auto; /* Changed from scroll to auto */
    margin: 0 auto;
    width: 100%;

    box-shadow: -4px 13px 20px -1px rgba(0,0,0,0.75);
    -webkit-box-shadow: -4px 13px 20px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: -4px 13px 20px -1px rgba(0,0,0,0.75);
}

.terminal-line {
    margin: 0;
    white-space: pre-wrap;
}

.p_gross{
    font-size: .8rem;
}