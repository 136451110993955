/* Extra small devices (phones, less than 576px) */
@media (max-width: 575.98px) {
    .foooter_container > .container > .MuiGrid-container{
        flex-direction: column-reverse !important;
    }
    .me-auto {
        flex-direction: column !important;
    }
    .login_page{
        padding-top: 3rem;
        padding-bottom: 3rem;
    }
    .float_home, .float_home1{
        display: none;
    }
    .navbar-nav{
        gap: .3rem !important;
    }
    .btn_about{
        font-size: .8rem !important;
        text-wrap: nowrap !important;
        margin-left: .1rem !important;
    }
    .contact_page{
        padding: .1rem .8rem;
    }
    .contact_form{
        padding: 30px;
    }
    /* CSS rules */
    .p_about{
        width: 100%;
    }
    .h1_home{
        font-size: 3rem;
        line-height: 3rem;
        padding-top: 2rem;
    }
    .home_page .p_1 {
        font-size: 1rem;
        width: 90%;
    }
    .container_mining > div:nth-child(1) {
        padding: 1rem 1.6rem !important;
    }

    .home_minting{
        padding: 1rem;
    }

    .marks > div > div:nth-child(1) {
        font-size: .8rem;
    }
    .marks > div > div:nth-child(3){
        font-size: .7rem;
    }
    .marks > div > .icon_marks > svg {
        font-size: .9rem;
    }
    .marks > div > .icon_marks {
        padding: .01rem .3rem;
    }
    .MuiSlider-colorSecondary{
        width: 100% !important;
    }
    .marks > div:nth-child(4) {
        left: -18px;
    }
    .marks > div:nth-child(5) {
        left: -13px;
    }
    .marks > div:nth-child(6) {
        left: -5px;
    }
    
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
/* CSS rules */
.float_home, .float_home1{
    display: none;
}
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
/* CSS rules */
    .float_home, .float_home1{
        display: none;
    }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1499.98px) {
    .float_home, .float_home1{
        width: 19rem;
        top: 12rem;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1500px) {
/* CSS rules */
}