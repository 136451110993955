.pos-r {
    position: relative;
}

.pos-a {
    position: absolute;
}

.d-lg-b {
    display: block;
}

.about-scroll-line {
    top: 35px;
    left: 50%;
    width: 2px;
    bottom: 0;
    display: block;
    position: absolute;
    height: 68rem;
    border-radius: 2px;
    transform: translateX(-50%);
    background: linear-gradient(180deg, #b36ef6 0%, #7540ef 100%);
}

.about-box {
    border-radius: 30px;
    background: #19192b;
    width: calc(50% - 75px);
    padding: 26px 12px 12px;
    width: calc(50% - 50px);
    margin-bottom: 80px;
    text-align: start;
    font-weight: 600;
}

.about-box_left {
    float: left;
    margin-bottom: 180px;
}

.about-box_right {
    float: right;
    margin-top: 168px;
}

.about-box_right:nth-child(1){
    margin-top: 268px !important;
}

.about-box__nmb {
    width: 48px;
    height: 48px;
    display: flex;
    left: 50%;
    text-align: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    justify-content: center;
    transform: translate(-50%, -100%);
    color: #fff;
    font-size: 20px;
    line-height: 1.2;
}

.bgc-purple-lighter {
    background-color: #c97ef8;
}

.about-box_left .about-box__nmb::after {
    right: 100%;
    width: 26px;
    content: "";
    position: absolute;
    border-bottom: 2px dashed transparent;
    border-bottom-color: #c97ef8;
}

.about-box_right .about-box__nmb::after {
    left: 100%;
    width: 26px;
    right: auto;
    content: "";
    position: absolute;
    border-bottom: 2px dashed transparent;
    border-bottom-color: #c97ef8;
}

.about_box_last{
    background-color: #7540ef;
}

.text-centerM{
    text-align: center;
    width: 90% !important;
    margin: auto;
    margin-bottom: 4rem !important;
}

@media (max-width: 800px) {
    .about-scroll-line {
        top: 5rem;
    }
    .about-scroll-line {
        height: 62rem;
    }
}
@media (max-width: 600px) {
    .about-scroll-line, .about-box__nmb{
        display: none;
    }

    .about-scroll-line {
        height: 62rem;
    }

    .about-box{
        width: 93% !important;
        margin: 1rem !important;
        margin: auto;
        position: relative;
    }
}