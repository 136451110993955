.p_about{
    font-size: 1rem;
}

.about_component h1{
    font-weight: 600;
    color: white;
    width: fit-content;
}

.title_about > svg{
    color: white;
    border-radius: 5px;
    margin-right: 1rem;
    padding: .2rem;
}

.title_about{
    font-weight: 600;
    font-size: 1.2rem;
}

.grid_in_ab{
    position: relative;
}
.position-relative{
    position: relative;
}
.sp_in_ab{
    position: absolute;
    height: 60%;
    width: 5px;
    top: -10px;
    left: -15px;
    background-color: #651fff;
}

.img_t_about{
    position: absolute;
    right: -50%;
    top: -50%;
    width: 150%;
}

.btn_about{
    color: white !important;
    letter-spacing: .05em;
    text-transform:none !important;
    font-size: 1rem;
    line-height: 1.5rem;
    background-image: none;
    border-radius: 20px;
    padding-left: 18px !important;
    padding-right: 18px !important;
    padding-bottom: .3rem !important;
    padding-top: .3rem !important;
    border: 1px solid black !important;
    background-color: black !important;
    font-size: 1.1rem !important;
    margin-top: 1rem !important;
}