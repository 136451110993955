.home_page{
    padding-bottom: 3rem;
}
.h1_home{
    text-align: center;
    font-weight: 700;
    font-size: 4.5rem;
    letter-spacing: -.02em;
    line-height: 4.75rem;
    
    color: white;
}

.home_page .p_1{
    font-size: 1.25rem;
    line-height: 1.875rem;
}

.p_home{
    width: 60%;
    margin: auto;
    text-align: center;
    padding-top: 1rem;
    color: white;
}

.home_header{
    background-image: url('../img/front_bg.webp');
    min-height: 75vh;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding-top: 6%;
}

.float_home{
  position: absolute;
  left: 0;
  top: 10rem;
  width: 25rem;
}

.float_home1{
  position: absolute;
  right: 0;
  top: 10rem;
  width: 25rem;
}


.home_btngroup{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    width: 100%;
    justify-content: center;
    gap: 2rem;
    padding-top: 1.5rem;
}

.home_btngroup > button:nth-child(1) {
    color: rgb(255 255 255/ 1) !important;
    letter-spacing: .05em;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.5rem;
    background-image: linear-gradient(90deg, #8b55ff, #651fff 51.04%, #834bff);
    border-radius: 20px;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

.home_btngroup > button:nth-child(2) {
    color: white;
    letter-spacing: .05em;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.5rem;
    background-image: none;
    border-radius: 20px;
    padding-left: 18px;
    padding-right: 18px;
    padding-bottom: 1rem;
    padding-top: 1rem;
    border: 1px solid white !important;
}


.home_btngroup > button:hover{
    background-color: white !important;
    color: black !important;
    background-image: none;
}

.p_home_1{
    font-size: .875rem;
    line-height: 1.125rem;
    text-transform: uppercase;
    padding-top: 5rem;
    text-align: center;
    color: white;
    font-weight: 600;
}

.p_home_1 > img{
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 1rem;
}

.about_component{
    padding-top: 5rem;
    padding-bottom: 5rem;
    overflow: hidden;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
    from {
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes rotating {
    from {
      -ms-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    to {
      -ms-transform: rotate(360deg);
      -moz-transform: rotate(360deg);
      -webkit-transform: rotate(360deg);
      -o-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  .rotating {
    -webkit-animation: rotating 2s linear infinite;
    -moz-animation: rotating 2s linear infinite;
    -ms-animation: rotating 2s linear infinite;
    -o-animation: rotating 2s linear infinite;
    animation: rotating 2s linear infinite;
  }

  .marks{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin-block-start: -8px;
    padding: 6px;
    margin-block-end: 12px;
  }

  .marks > div{
    width: 48px;
    text-align: center;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    
    position: relative;
    cursor: pointer;
  }

  .marks > div > .icon_marks{
    background-color: #651fff;
    width: fit-content;
    color: white;
    border-radius: 50%;
    padding: .25rem .4rem;
  }

  .marks > div > .icon_marks > svg{
    font-size: 1.2rem
  }

  .marks > div:nth-child(1){
    left: -30px;
  }
  .marks > div:nth-child(2){
    left: -29px;
  }
  .marks > div:nth-child(3){
    left: -22px;
  }
  .marks > div:nth-child(4){
    left: -10px;
  }
  .marks > div:nth-child(5){
    left: 10px;
  }
  .marks > div:nth-child(6){
    left: 18px;
  }

  .marks > div:nth-child(7){
    left: 10px;
  }

  .marks > div:nth-child(8){
    left: 20px;
  }

  .marks > div > div{
    text-align: center;
    width: 100%;
  }

  .marks > div > div:nth-child(1){
    font-weight: 600;
    cursor: pointer;
    color: #797d8b;
    -webkit-font-smoothing: antialiased;
    font-size: 1.1rem;
  }

  .marks > div > div:nth-child(3){
    font-weight: 100;
    cursor: pointer;
    color: #797d8b;
    -webkit-font-smoothing: antialiased;
    font-size: .8rem;
    padding-top: .1rem;
  }

  .selected_mark > div:nth-child(1), .selected_mark > div:nth-child(3){
    color: white !important;
  }

  .display_spacebetween{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  .mining_img{
    width: 4rem;
  }

  .header_mining > div:nth-child(1){
    font-size: 1.4rem;
    color: #0CE2E0;
    font-weight: 500;
  }

  .header_mining > div:nth-child(1) > span {
    color: white !important;
    font-weight: 800;
    font-size: 1.6rem;
  }

  .container_mining, .container_mining1{
    background-color: #10172C;
    padding: 0 !important;
    border-radius: 10px;
    position: relative;
    overflow: hidden;
  }

  .container_mining > div:nth-child(1){
    padding: 1rem 2.9rem !important;
  }

  .grid_mining{
    border-top: 1px solid rgb(87, 87, 87);
    margin: 0 !important;
  }

  .grid_mining > .MuiGrid-item:nth-child(1),
  .grid_mining > .MuiGrid-item:nth-child(2),
  .grid_mining > .MuiGrid-item:nth-child(3){
    border-right: 1px solid rgb(87, 87, 87);
    border-bottom: 1px solid rgb(87, 87, 87);
  }

  .grid_mining > .MuiGrid-item{
    padding: 12px 16px !important;
  }

  .grid_label{
    font-size: 12px;
    line-height: 16px;
    color: #9fa9b0;
    margin-bottom: 0 !important;
  }

  .grid_value{
    font-size: 32px;
    line-height: 40px;
    color: white;
    font-weight: 600;
  }

  .grid_subvalue{
    color: #959dae;
    font-size: 12px;
    line-height: 16px;
  }

.minting_check{
  background: #10172C;
  color: white;
  text-align: center;
  padding: 1rem 2rem;
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 10px;
  height: 100%;
}

.minting_check > svg{
  color: #0CE2E0;
  margin-right: 1rem;
}

.display_center{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.stats_component{
  position: relative;
  overflow: hidden;
}

.stats_component::before{
  position: absolute;
    top: 0px;
    left: 0px;
    content: "";
    width: 80px;
    height: 80px;
    background-color: #1400F5;
    filter: blur(90px);
    z-index: 1;
    border-radius: 50%;
}

.stats_component::after{
  position: absolute;
    bottom: 0px;
    right: 0px;
    content: "";
    width: 80px;
    height: 80px;
    background-color: #B500F5;
    filter: blur(90px);
    z-index: 1;
    border-radius: 50%;
}

.stats_component h1{
  font-weight: 600;
  color: white;
  width: -moz-fit-content;
  width: fit-content;
  text-align: center;
}

.stats_home_title{
  font-size: 20px;
  font-weight: 700;
}

.numbers_stats_home{
  display: inline-block;
  vertical-align: middle;
  position: relative;
  line-height: 1.3;
  font-weight: 600;
  font-size: 2.5rem;
}

.numbers_stats_home > span{
  top: -.5em;
  position: relative; 
  font-size: .75em;
  line-height: 0;
  vertical-align: baseline;
}

.p_contact{
  font-size: 1.0625rem;
  color: #6c757d;
  text-align: start;
}

.contact_page{
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
}

.contact_form{
  position: relative;
  background-color: #1F1D2F !important;
  padding: 60px;
  border-radius: 8px;
  overflow: hidden;
  z-index: 1;
}

.contact_form:before {
  position: absolute;
  top: 0px;
  left: 0px;
  content: "";
  width: 123px;
  height: 352px;
  background-color: #3100F5;
  filter: blur(188px);
  border-radius: 50%;
  z-index: -1;
  opacity: 0.4;
}

.contact_form:after {
  position: absolute;
  bottom: 0px;
  right: 0px;
  content: "";
  width: 123px;
  height: 300px;
  background-color: #F59300;
  filter: blur(188px);
  border-radius: 50%;
  z-index: -1;
  opacity: 0.4;
}

.form-label{
  width: 100%;
  text-align: start;
  font-weight: 600;
}

.form-label > span{
  color: red;
}

.display_start{
  display: flex;
  justify-content: flex-start;
}

.toggle_home > .navbar-toggler-icon{
  color: white !important;
}

