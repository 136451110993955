.plan_h1{
    font-size: 1.2rem;
    font-weight: 600;
    width: 100%;
    text-align: start;
}

.plan_l{
    text-align: start;
    font-weight: 400;
    color: #b4b4b4;
    padding-bottom: 1rem;
}

.plan_item{
    padding: 32px;
    border-radius: 8px;
    height: 100%;
    transition: 0.3s;
    position: relative;

    box-shadow: -4px 13px 20px -1px rgba(0,0,0,0.75);
    -webkit-box-shadow: -4px 13px 20px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: -4px 13px 20px -1px rgba(0,0,0,0.75);
}

.plan_price{
    font-size: 3rem;
    font-weight: 700;
    padding: 10px 0;
    text-align: start;
    line-height: 1.3;
}

.plan_price span {
    font-size: 2rem;
}

.plan_features{
    text-align: start;
    display: flex;
    flex-direction: column;
    gap: .4rem;
    padding-top: .8rem;
    font-weight: 600;
}

.plan_features > div > svg{
    margin-right: .5rem;
}

.plan_features > div:nth-child(1){
    display: flex;
    justify-content: center;
}

.plan_features > div > img{
    width: 50%;
}

.plan_pop_p{
    text-align: center;
    font-size: 1.1rem;
}

.plan_pop_p > span{
    color: red;
}

.confirm_btn{
    background-color: rgb(0, 177, 0) !important;
    color: white !important;
}

.helper_popup:hover{
    cursor: pointer;
    text-decoration: underline;
}

.toggle_tutorial > .MuiButton-contained{
    color: white !important;
}

.toggle_tutorial > button{
    font-size: .9rem;
    font-weight: 600;
    padding: .2rem 1.4rem !important;
}