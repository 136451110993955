.login_page{
    flex: 1;
    /*background-image: url('../../img/sign-in-side-bg.png');*/
    background-image: url('../../img/front_bg.webp');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;

    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;

    padding-top: 6rem;
    padding-bottom: 6rem;
}

.full-width{
    width: 100%;
}

.other_login{
    text-align: center;
    font-size: 1.0625rem;
    font-weight: 400;
    color: rgb(97, 97, 97);
}

.other_login > span{
    text-decoration: underline;
    font-weight: 600;
    cursor: pointer;
    color: white;
}

.login_form{
    box-shadow: -4px 13px 20px -1px rgba(0,0,0,0.75);
    -webkit-box-shadow: -4px 13px 20px -1px rgba(0,0,0,0.75);
    -moz-box-shadow: -4px 13px 20px -1px rgba(0,0,0,0.75);
}

.btn_user{
    color: white !important;
    border: 1px solid white !important;
    font-size: .7rem !important;
    border-radius: 10px !important;
}
.btn_user1{
    color: black !important;
    border: 1px solid black !important;
    font-size: .7rem !important;
    border-radius: 10px !important;
}
.btn_user > svg, .btn_user1 > svg{
    width: 1.2rem;
    height: auto;
}

.btn_user:hover, .btn_user1:hover{
    color: white !important;
    background-color: black !important;
}

.css-phbxid-MuiListItem-root.Mui-selected{
    background-color: black !important;
    color: white !important;
}

.css-phbxid-MuiListItem-root.Mui-selected > .MuiButtonBase-root > .MuiListItemIcon-root{
    color: white !important;
}

.css-gyjaqv-MuiButtonBase-root-MuiListItemButton-root.Mui-selected{
    background-color: rgba(0, 0, 0, 0.123) !important;
}

.box_avatar_card{
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    margin-top: -24px;
    opacity: 1;
    color: rgb(255, 255, 255);
    border-radius: 0.75rem;
    box-shadow: rgba(0, 0, 0, 0.14) 0rem 0.25rem 1.25rem 0rem, rgba(64, 64, 64, 0.4) 0rem 0.4375rem 0.625rem -0.3125rem;
}

.box_text_card{
    text-align: right;
    line-height: 1.25;
    opacity: 1;
    background: transparent;
    color: rgb(52, 71, 103);
    box-shadow: none;
}

.box_text_card > span{
    margin: 0px;
    font-size: 0.875rem;
    line-height: 1.5;
    letter-spacing: 0.02857em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(123, 128, 154);
    font-weight: 300;
}

.paper_card{
    display: flex;
    flex-direction: column;
}

.box_text_card > h4, .paper_card > h4{
    margin: 0px;
    font-size: 1.7rem !important;
    line-height: 1.375;
    font-weight: 700;
    letter-spacing: 0.00735em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(52, 71, 103);
}

.box_text_card > h4 > span{
    margin: 0px;
    font-size: 0.875rem;
    line-height: 1.5;
    letter-spacing: 0.02857em;
    opacity: 1;
    text-transform: none;
    vertical-align: unset;
    text-decoration: none;
    color: rgb(76, 175, 80);
    font-weight: 700;
}

.divider_card{
    flex-shrink: 0  !important;
    border-top: 0px solid rgba(0, 0, 0, 0.12) !important;
    border-right: 0px solid rgba(0, 0, 0, 0.12)  !important;
    border-left: 0px solid rgba(0, 0, 0, 0.12)  !important;
    background-color: transparent  !important;
    height: 0.0625rem  !important;
    padding: .8rem 0px !important;
    border-bottom: none !important;
    opacity: 0.25  !important;
}



.paper_card{
    border-radius: 10px !important;
}

#acquisitions{
    height: auto !important;
    width: 100% !important;
}

.full-height{
    height: 100%;
}